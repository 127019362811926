import styled from 'styled-components';

export const Wrapper = styled.div`
  border: 2px solid #696969;
  width: auto;
  margin-bottom: 30px;
`;

export const Header = styled.div`
  background-color: ${props => (props.isNew ? '#FFF240' : '#c3f1ff')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 20px;
`;

export const Title = styled.h3`
  margin: 0;
`;

export const EditIcon = styled.i`
  cursor: pointer;
  margin-left: 12px;
  &:hover {
    color: #4f81c7;
  }
`;

export const TrashIcon = styled.i`
  cursor: pointer;
  &:hover {
    color: #d35656;
  }
`;

export const SearchWrapper = styled.div`
  width: 800px;
`;

export const Body = styled.div`
  overflow: auto;
  max-height: 600px;
`;

export const SkillWrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d3d3d3;

  &:hover {
    background-color: #ecfcff;
  }

  &:last-child {
    border-bottom: none;
  }
`;

export const SkillCTAs = styled.div`
  display: flex;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid #d3d3d3;
  padding: 20px;
`;

export const SavedText = styled.span`
  color: #6fb98f;
`;
