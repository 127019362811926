import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const TitleWrapper = styled.div`
  padding-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.div`
  font-size: 60px;
  font-weight: bold;
  color: #32407b;
  text-shadow: 3px 3px 2px #465881, 6px 6px 2px #46b5d1;
`;

export const Wrapper = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Navlinks = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Icon = styled.i`
  margin-right: 5px;
`;

export const Content = styled.div`
  background-color: white;
  width: 80%;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
`;

export const Link = styled(NavLink)`
  color: inherit;

  &:after {
    content: '/';
    margin-left: 20px;
    margin-right: 20px;
  }

  &:last-child::after {
    content: none;
  }
`;

export const LinkText = styled.span`
  color: ${props => (props.isselected === 'true' ? '#3282b8' : 'inherit')};
  font-weight: ${props => (props.isselected === 'true' ? 'bold' : 'normal')};

  &:hover {
    color: #3282b8;
    font-weight: bold;
  }
`;
