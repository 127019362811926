import React from 'react';

import { ConnectedRouter } from 'connected-react-router';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route } from 'react-router';

import { history, store } from './store';

import App from './App';

import { ErrorBoundary } from './components';

ReactDOM.render(
  <Provider store={store}>
    <ErrorBoundary>
      <ConnectedRouter history={history}>
        <Route path="/" component={App} />
      </ConnectedRouter>
    </ErrorBoundary>
  </Provider>,
  document.getElementById('app'),
);
