import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { Error, Loader } from '../../components';

import { fetchGroup } from './Actions';
import Group from './Group';
import Guide from './Guide';
import {
  getGroupIds,
  getFetchGroupError,
  getFetchGroupLoading,
  getFetchGroupSuccess,
  hasUnsavedGroups,
} from './Selectors';

import { Content, Header, Left, Right } from './Earth.sc';
import './animation.css';

export class Earth extends Component {
  componentDidMount() {
    this.props.fetchGroup();
  }

  componentDidUpdate(prevProps) {
    if (
      !this.props.fetchLoading &&
      !this.props.hasUnsavedGroups &&
      prevProps.hasUnsavedGroups !== this.props.hasUnsavedGroups
    ) {
      this.props.fetchGroup();
    }
  }

  render() {
    const { fetchError, fetchLoading, fetchSuccess, groupIds } = this.props;
    return (
      <React.Fragment>
        <Header>
          <h2>
            <i className="fas fa-globe-asia" />
            &nbsp;Earth: Skills Curation
          </h2>
        </Header>
        <Content>
          <Left>
            {fetchLoading ? <Loader /> : null}
            {fetchSuccess ? (
              <TransitionGroup>
                {groupIds.map(x => (
                  <CSSTransition key={x} timeout={500} classNames="fade">
                    <Group key={x} id={x} />
                  </CSSTransition>
                ))}
              </TransitionGroup>
            ) : null}
            {fetchError ? (
              <Error
                description={fetchError.description}
                message={fetchError.message}
              />
            ) : null}
          </Left>
          <Right>
            <Guide />
          </Right>
        </Content>
      </React.Fragment>
    );
  }
}

Earth.propTypes = {
  groupIds: PropTypes.array,

  fetchLoading: PropTypes.bool,
  fetchSuccess: PropTypes.bool,
  hasUnsavedGroups: PropTypes.bool,

  fetchGroup: PropTypes.func,

  fetchError: PropTypes.object,
};

export default connect(
  state => ({
    fetchError: getFetchGroupError(state),
    fetchLoading: getFetchGroupLoading(state),
    fetchSuccess: getFetchGroupSuccess(state),
    groupIds: getGroupIds(state),
    hasUnsavedGroups: hasUnsavedGroups(state),
  }),
  dispatch => ({
    fetchGroup: () => dispatch(fetchGroup()),
  }),
)(Earth);
