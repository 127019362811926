import React from 'react';

import styled from 'styled-components';

const Guide = () => (
  <Wrapper>
    <Title>Curation Guidelines</Title>
    <ol>
      <li>
        Click on <strong>Does Not Belong</strong> if the skill does not belong
        in the group
      </li>
      <li>
        Click on <strong>Add Another Label</strong> to add this skill to another
        group. This group will appear at the bottom of the list.
      </li>
      <li>
        Break up conjunctions ('and', '/', '&') by adding another label for this
        skill.
      </li>
      <li>Ensure the newly added label is spelled correctly</li>
      <li>Expand the label if it is an acronym. Eg. Above The Line (ATL)</li>
      <li>
        The next batch of skills will be automatically fetched when all groups
        in the list have been successfully saved
      </li>
    </ol>
  </Wrapper>
);

const Wrapper = styled.div`
  padding: 20px;
  background-color: #ecfcff;
  border: 2px solid #bbe1fa;
`;

const Title = styled.h4`
  margin: 0;
`;

export default Guide;
