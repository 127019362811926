import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import thunk from 'redux-thunk';

import EarthReducer from './modules/Earth/Reducer';
import LoginReducer from './modules/Login/Reducer';

const history = createBrowserHistory();

const rootReducer = history =>
  combineReducers({
    earth: EarthReducer,
    login: LoginReducer,
    router: connectRouter(history),
  });

const store = createStore(
  rootReducer(history),
  composeWithDevTools(applyMiddleware(thunk, routerMiddleware(history))),
);

export { history, store };
