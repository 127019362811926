import get from 'lodash.get';

export const isNewEntity = id => id.includes('new');

export const getGroupIds = state => get(state, 'earth.groups.result', []);

export const getGroup = (state, id) => get(state, 'earth.entities.group')[id];

export const getFetchGroupLoading = state =>
  get(state, 'earth.groups.fetch.loading');
export const getFetchGroupSuccess = state =>
  get(state, 'earth.groups.fetch.success');
export const getFetchGroupError = state =>
  get(state, 'earth.groups.fetch.error');

export const getNormalizedSkillIds = state => get(state, 'earth.search.result');
export const getNormalizedSkills = state => {
  const ids = getNormalizedSkillIds(state);
  const entities = get(state, 'earth.entities.normalizedSkill');
  const skills = ids.map(x => entities[x]);
  return skills;
};

export const getSkillEntities = state => {
  const skills = {
    ...get(state, 'earth.entities.skill'),
    ...get(state, 'earth.entities.normalizedSkill'),
  };
  return skills;
};

export const getSaveGroupSuccess = (state, id) => {
  const states = get(state, 'earth.groups.save.success', []);
  return states.includes(id);
};

export const getSaveGroupLoading = (state, id) => {
  const states = get(state, 'earth.groups.save.loading', []);
  return states.includes(id);
};

export const getSaveGroupError = (state, id) => {
  const states = get(state, 'earth.groups.save.error', {});
  return states[id];
};

export const hasUnsavedGroups = state => {
  const saved = get(state, 'earth.groups.save.success', []);
  const groupIds = getGroupIds(state);
  return groupIds.length !== saved.length;
};
