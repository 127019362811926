import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

const CTAButton = ({ color, icon, isSmall, label, loading, ...props }) => (
  <Button color={colors[color] || colors.default} isSmall={isSmall} {...props}>
    {icon ? <LeftIcon className={`fas fa-${icon}`} /> : null}
    {label}
    {loading ? <LoadingIcon className="fas fa-circle-notch fa-spin" /> : null}
  </Button>
);

CTAButton.propTypes = {
  isSmall: PropTypes.bool,
  loading: PropTypes.bool,

  color: PropTypes.string,
  icon: PropTypes.string,
  label: PropTypes.string,
};

export const colors = {
  red: '#d35656',
  green: '#6fb98f',
  default: '#4f81c7',
};

const Button = styled.button`
  color: ${props => props.color};
  text-transform: uppercase;
  background: white;
  padding: ${props => (props.isSmall ? '8px' : '12px')};
  border: 4px solid ${props => props.color};
  border-radius: 6px;
  display: inline-block;
  transition: all 0.3s ease 0s;
  font-family: inherit;
  font-size: ${props => (props.isSmall ? '14px' : '16px')};
  letter-spacing: 1.5px;
  font-weight: bold;
  cursor: pointer;
  outline: none;

  &:hover {
    color: #4d4646;
    border-radius: 50px;
    border-color: #4d4646;
    transition: all 0.3s ease 0s;
  }

  &:disabled {
    color: #c5c5c5;
    border: 4px solid #c5c5c5;
    cursor: not-allowed;
  }
`;

const LoadingIcon = styled.i`
  margin-left: 10px;
`;

const LeftIcon = styled.i`
  margin-right: 5px;
`;

export default CTAButton;
