import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 40px 20px;
  border-bottom: 2px solid #d3d3d3;
`;

export const Content = styled.div`
  padding: 30px 40px;
  display: flex;
  flex-direction: row;
`;

export const Left = styled.div`
  width: 75%;
  display: flex;
  flex-direction: column;
`;

export const Right = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  width: 25%;
`;
