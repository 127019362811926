import { handleActions } from 'redux-actions';

import { Actions } from './Actions';

export const initialState = {
  entities: {
    group: {},
    normalizedSkill: {},
    skill: {},
  },
  groups: {
    result: [],
    save: {
      success: [],
      loading: [],
      error: {},
    },
    fetch: {
      loading: false,
      success: false,
      error: null,
    },
  },
  search: {
    result: [],
    loading: false,
    success: false,
    error: null,
  },
};

export default handleActions(
  {
    [Actions.GROUP_REQUEST]: state => ({
      ...state,
      entities: {
        ...state.entities,
        group: {},
      },
      groups: {
        ...state.groups,
        result: [],
        save: initialState.groups.save,
        fetch: {
          ...initialState.groups.fetch,
          loading: true,
        },
      },
    }),
    [Actions.GROUP_SUCCESS]: (state, action) => ({
      ...state,
      entities: {
        ...state.entities,
        ...action.payload.entity,
      },
      groups: {
        ...state.groups,
        result: [...state.groups.result, action.payload.groupId],
        fetch: {
          ...initialState.groups.fetch,
          success: true,
        },
      },
    }),
    [Actions.GROUP_FAILURE]: (state, action) => ({
      ...state,
      groups: {
        ...initialState.groups,
        fetch: {
          ...initialState.groups.fetch,
          error: action.payload,
        },
      },
    }),
    [Actions.NORMALIZED_SKILLS_REQUEST]: state => ({
      ...state,
      search: {
        ...initialState.search,
        loading: true,
      },
    }),
    [Actions.NORMALIZED_SKILLS_SUCCESS]: (state, action) => ({
      ...state,
      entities: {
        ...state.entities,
        normalizedSkill: {
          ...state.entities.normalizedSkill,
          ...action.payload.entities.normalizedSkill,
        },
      },
      search: {
        ...state.search,
        result: action.payload.result,
        loading: false,
        success: true,
        error: null,
      },
    }),
    [Actions.NORMALIZED_SKILLS_FAILURE]: (state, action) => ({
      ...state,
      search: {
        ...state.search,
        loading: false,
        success: false,
        error: action.payload,
      },
    }),
    [Actions.SET_GROUP_NORMALIZED_SKILL_ID]: (state, action) => ({
      ...state,
      entities: {
        ...state.entities,
        group: {
          ...state.entities.group,
          [action.payload.groupId]: {
            ...state.entities.group[action.payload.groupId],
            normalizedSkill: action.payload.skillId,
          },
        },
      },
    }),
    [Actions.ADD_NORMALIZED_SKILL_TO_ENTITIES]: (state, action) => ({
      ...state,
      entities: {
        ...state.entities,
        normalizedSkill: {
          ...state.entities.normalizedSkill,
          ...action.payload,
        },
      },
    }),
    [Actions.REMOVE_SKILL_FROM_GROUP]: (state, action) => ({
      ...state,
      entities: {
        ...state.entities,
        group: {
          ...state.entities.group,
          [action.payload.groupId]: {
            ...state.entities.group[action.payload.groupId],
            skills: state.entities.group[action.payload.groupId].skills.filter(
              id => id !== action.payload.skillId,
            ),
          },
        },
      },
    }),
    [Actions.ADD_GROUP_TO_STATE]: (state, action) => ({
      ...state,
      entities: {
        ...state.entities,
        group: {
          ...state.entities.group,
          ...action.payload.entity,
        },
      },
      groups: {
        ...state.groups,
        result: [
          ...state.groups.result.slice(0, 1),
          action.payload.groupId,
          ...state.groups.result.slice(1),
        ],
      },
    }),
    [Actions.REMOVE_GROUP]: (state, action) => {
      delete state.entities.group[action.payload];
      return {
        ...state,
        groups: {
          ...state.groups,
          result: state.groups.result.filter(x => x !== action.payload),
        },
      };
    },
    [Actions.SAVE_MAPPINGS_REQUEST]: (state, action) => ({
      ...state,
      groups: {
        ...state.groups,
        save: {
          ...state.groups.save,
          loading: [
            ...new Set(state.groups.save.loading.concat(action.payload)),
          ],
          success: state.groups.save.success.filter(x => x !== action.payload),
          error: {
            ...state.groups.save.error,
            [action.payload]: null,
          },
        },
      },
    }),
    [Actions.SAVE_MAPPINGS_SUCCESS]: (state, action) => ({
      ...state,
      groups: {
        ...state.groups,
        save: {
          ...state.groups.save,
          loading: state.groups.save.loading.filter(x => x !== action.payload),
          success: [
            ...new Set(state.groups.save.success.concat(action.payload)),
          ],
          error: {
            ...state.groups.save.error,
            [action.payload]: null,
          },
        },
      },
    }),
    [Actions.SAVE_MAPPINGS_FAILURE]: (state, action) => ({
      ...state,
      groups: {
        ...state.groups,
        save: {
          ...state.groups.save,
          loading: state.groups.save.loading.filter(
            x => x !== action.payload.groupId,
          ),
          success: state.groups.save.success.filter(
            x => x !== action.payload.groupId,
          ),
          error: {
            ...state.groups.save.error,
            [action.payload.groupId]: action.payload.error,
          },
        },
      },
    }),
    [Actions.SAVE_NEW_NORMALIZED_SKILL_FAILURE]: (state, action) => ({
      ...state,
      groups: {
        ...state.groups,
        save: {
          ...state.groups.save,
          loading: state.groups.save.loading.filter(
            x => x !== action.payload.groupId,
          ),
          success: state.groups.save.success.filter(
            x => x !== action.payload.groupId,
          ),
          error: {
            ...state.groups.save.error,
            [action.payload.groupId]: action.payload.error,
          },
        },
      },
    }),
    [Actions.RELEVANCE_FEEDBACK_LOGGING_FAILURE]: (state, action) => ({
      ...state,
      groups: {
        ...state.groups,
        save: {
          ...state.groups.save,
          loading: state.groups.save.loading.filter(
            x => x !== action.payload.groupId,
          ),
          success: state.groups.save.success.filter(
            x => x !== action.payload.groupId,
          ),
          error: {
            ...state.groups.save.error,
            [action.payload.groupId]: action.payload.error,
          },
        },
      },
    }),
  },
  initialState,
);
