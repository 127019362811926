import React from 'react';

import { Content, Header, Left, Right } from './Universe.sc';

const Universe = () => (
  <React.Fragment>
    <Header>
      <h2>
        <i className="fas fa-atom" />
        &nbsp;Universe: Under Construction
      </h2>
    </Header>
    <Content>
      <Left />
      <Right />
    </Content>
  </React.Fragment>
);

export default Universe;
