import React, { Component } from 'react';

import get from 'lodash.get';
import PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { connect } from 'react-redux';

import { CTAButton, Error, Searchbox } from '../../components';
import './animation.css';

import {
  addGroup,
  fetchNormalizedSkills,
  removeGroup,
  removeSkill,
  saveGroup,
  updateGroupNormalizedSkill,
} from './Actions';
import {
  getGroup,
  getSaveGroupError,
  getSaveGroupLoading,
  getSaveGroupSuccess,
  getNormalizedSkills,
  getSkillEntities,
  isNewEntity,
} from './Selectors';

import {
  Body,
  EditIcon,
  Footer,
  Header,
  Title,
  TrashIcon,
  SavedText,
  SearchWrapper,
  SkillCTAs,
  SkillWrapper,
  Wrapper,
} from './Group.sc';

export class Group extends Component {
  constructor(props) {
    super(props);
    this.onClickEdit = this.onClickEdit.bind(this);
    this.onClickCancel = this.onClickCancel.bind(this);
    this.onClickConfirm = this.onClickConfirm.bind(this);
    this.onSelectLabel = this.onSelectLabel.bind(this);
    this.state = {
      editMode: false,
      selectedLabel: null,
    };
  }

  onClickEdit() {
    this.setState({
      editMode: true,
    });
  }

  onClickCancel() {
    this.setState({
      editMode: false,
      selectedLabel: null,
    });
  }

  onClickConfirm() {
    if (
      this.state.selectedLabel &&
      this.state.selectedLabel.name &&
      this.state.selectedLabel.name.length
    ) {
      this.props.updateGroupNormalizedSkill(this.state.selectedLabel);
    }
    this.setState({
      editMode: false,
      selectedLabel: null,
    });
  }

  onSelectLabel(input) {
    this.setState({
      selectedLabel: input,
    });
  }

  render() {
    const {
      error,
      group,
      groupSkillIds,
      id,
      loading,
      options,
      success,
      skills,
    } = this.props;

    if (!id || !group) {
      return null;
    }

    const { editMode } = this.state;

    return (
      <Wrapper key={id}>
        <Header isNew={isNewEntity(id)}>
          {!editMode ? (
            <React.Fragment>
              <Title>
                <span>
                  {group.normalizedSkill
                    ? skills[group.normalizedSkill].name
                    : 'Please add a label'}
                </span>
                {!success ? (
                  <EditIcon
                    onClick={this.onClickEdit}
                    title="Edit Label"
                    className="fas fa-edit"
                  />
                ) : null}
              </Title>
              <Title>
                {!success && isNewEntity(id) ? (
                  <TrashIcon
                    onClick={this.props.onClickRemoveGroup.bind(this)}
                    title="Remove group"
                    className="fas fa-trash-alt trash-icon"
                  />
                ) : null}
              </Title>
            </React.Fragment>
          ) : null}
          {editMode ? (
            <React.Fragment>
              <SearchWrapper>
                <Searchbox
                  fetchOptions={this.props.fetchOptions.bind(this)}
                  options={options}
                  handleOnChange={this.onSelectLabel}
                />
              </SearchWrapper>
              <CTAButton
                color="green"
                isSmall={true}
                label="Confirm"
                onClick={this.onClickConfirm}
              />
              <CTAButton
                isSmall={true}
                label="Cancel"
                onClick={this.onClickCancel}
              />
            </React.Fragment>
          ) : null}
        </Header>
        <Body>
          {groupSkillIds.length ? (
            <TransitionGroup>
              {groupSkillIds.map(x => (
                <CSSTransition key={x} timeout={400} classNames="fade">
                  <SkillWrapper key={x}>
                    <div>{skills[x].name}</div>
                    <SkillCTAs>
                      <CTAButton
                        color="green"
                        isSmall={true}
                        icon="plus"
                        label="Add Another Label"
                        onClick={this.props.onClickAddLabel.bind(this, x)}
                      />
                      {group.skills.length > 1 ? (
                        <React.Fragment>
                          <div key={x.id} style={{ width: '10px' }} />
                          <CTAButton
                            color="red"
                            isSmall={true}
                            icon="times"
                            label="Does Not Belong"
                            disabled={!group.normalizedSkill || editMode}
                            onClick={this.props.onClickRemoveSkill.bind(
                              this,
                              x,
                            )}
                          />
                        </React.Fragment>
                      ) : null}
                    </SkillCTAs>
                  </SkillWrapper>
                </CSSTransition>
              ))}
            </TransitionGroup>
          ) : (
            <SkillWrapper>No skills present</SkillWrapper>
          )}
        </Body>
        <Footer>
          {!success ? (
            <CTAButton
              isSmall={true}
              icon="Save"
              label="Save"
              onClick={this.props.onClickSave}
              loading={loading}
              disabled={!group.normalizedSkill || loading}
            />
          ) : null}
          {success ? <SavedText>Saved successfully!</SavedText> : null}
        </Footer>
        {error ? (
          <div style={{ marginBottom: '-30px' }}>
            <Error {...error} />
          </div>
        ) : null}
      </Wrapper>
    );
  }
}

Group.propTypes = {
  options: PropTypes.array,
  groupSkillIds: PropTypes.array,

  loading: PropTypes.bool,
  success: PropTypes.bool,

  fetchOptions: PropTypes.func,
  onClickAddLabel: PropTypes.func,
  onClickRemoveGroup: PropTypes.func,
  onClickRemoveSkill: PropTypes.func,
  onClickSave: PropTypes.func,
  updateGroupNormalizedSkill: PropTypes.func,

  error: PropTypes.object,
  group: PropTypes.object,
  skills: PropTypes.object,

  id: PropTypes.string,
};

export default connect(
  (state, ownProps) => ({
    error: getSaveGroupError(state, ownProps.id),
    loading: getSaveGroupLoading(state, ownProps.id),
    group: getGroup(state, ownProps.id),
    groupSkillIds: get(getGroup(state, ownProps.id), 'skills', []),
    options: getNormalizedSkills(state),
    success: getSaveGroupSuccess(state, ownProps.id),
    skills: getSkillEntities(state),
  }),
  (dispatch, ownProps) => ({
    fetchOptions: input => dispatch(fetchNormalizedSkills(input)),
    onClickAddLabel: x => dispatch(addGroup(x)),
    onClickRemoveGroup: () => dispatch(removeGroup(ownProps.id)),
    onClickRemoveSkill: skillId => dispatch(removeSkill(ownProps.id, skillId)),
    onClickSave: () => dispatch(saveGroup(ownProps.id)),
    updateGroupNormalizedSkill: label =>
      dispatch(updateGroupNormalizedSkill(ownProps.id, label)),
  }),
)(Group);
