import React, { Component } from 'react';

import CreatableSelect from 'react-select/creatable';
import PropTypes from 'prop-types';

class Searchbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: undefined,
    };
  }

  handleInputChange(input) {
    this.setState({
      inputValue: input,
    });
    this.props.fetchOptions(input);
  }

  render() {
    const { options } = this.props;
    const { inputValue } = this.state;

    return (
      <CreatableSelect
        placeholder="Type to search for a skill..."
        isSearchable={true}
        options={options}
        inputValue={inputValue}
        onInputChange={this.handleInputChange.bind(this)}
        onChange={this.props.handleOnChange}
        getNewOptionData={inputValue => ({
          id: `new-${inputValue}`,
          name: inputValue,
        })}
        getOptionLabel={option =>
          option.id.includes('new') ? (
            <span>
              [New Label] <strong>{option.name}</strong>
            </span>
          ) : (
            option.name
          )
        }
        getOptionValue={option => option.id}
        isValidNewOption={inputValue => inputValue.length}
        createOptionPosition="first"
      />
    );
  }
}

Searchbox.propTypes = {
  options: PropTypes.array.isRequired,
  fetchOptions: PropTypes.func,
  handleOnChange: PropTypes.func.isRequired,
};

export default Searchbox;
