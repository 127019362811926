import { schema } from 'normalizr';

const GroupSchema = new schema.Entity('group');
const SkillSchema = new schema.Entity('skill');
const NormalizedSkillSchema = new schema.Entity('normalizedSkill');

GroupSchema.define({
  normalizedSkill: NormalizedSkillSchema,
  skills: [SkillSchema],
});

export const Schemas = {
  GROUP: GroupSchema,
  GROUPS: [GroupSchema],
  NORMALIZED_SKILL: NormalizedSkillSchema,
  NORMALIZED_SKILLS: [NormalizedSkillSchema],
  SKILL: SkillSchema,
  SKILLS: [SkillSchema],
};
