import jwtDecode from 'jwt-decode';

import { getAccessToken } from './modules/Login/Selectors';

const includeBearer = (state, options = {}) => {
  const accessToken = getAccessToken(state);
  if (accessToken) {
    const jwt = jwtDecode(accessToken);
    // If token is valid
    if (!(jwt.exp < Date.now() / 1000)) {
      options.headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      return options;
    }
  }
  throw {
    message: 'Invalid Access Token',
    description: 'Please logout and login again.',
  };
};

export default includeBearer;
