import React from 'react';

import styled from 'styled-components';

const LandingAuthenticated = () => (
  <Wrapper>
    <i className="fas fa-hand-point-up" />
    &nbsp; Please select a project above! &nbsp;
    <i className="fas fa-hand-point-up" />
  </Wrapper>
);

const Wrapper = styled.div`
  padding: 50px;
  font-size: 24px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export default LandingAuthenticated;
