import createAuth0Client from '@auth0/auth0-spa-js';
import { createAction } from 'redux-actions';

import { getAuth0 } from './Selectors';

export const Actions = {
  LOGIN_REQUEST: 'app/login/LOGIN_REQUEST',
  LOGIN_SUCCESS: 'app/login/LOGIN_SUCCESS',
  LOGIN_FAILURE: 'app/login/LOGIN_FAILURE',

  HANDLE_REDIRECT_REQUEST: 'app/login/HANDLE_REDIRECT_REQUEST',
  HANDLE_REDIRECT_SUCCESS: 'app/login/HANDLE_REDIRECT_SUCCESS',
  HANDLE_REDIRECT_FAILURE: 'app/login/HANDLE_REDIRECT_FAILURE',

  GET_AUTH_USER_SUCCESS: 'app/login/GET_AUTH_USER_SUCCESS',

  AUTH0_AUTHENTICATED_SUCCESS: 'app/login/AUTH0_AUTHENTICATED_SUCCESS',

  LOGOUT_SUCCESS: 'app/login/LOGOUT_SUCCESS',

  ACCESS_TOKEN_SUCCESS: 'app/login/ACCESS_TOKEN_SUCCESS',
};

export const loginRequest = createAction(Actions.LOGIN_REQUEST);
export const loginSuccess = createAction(Actions.LOGIN_SUCCESS);
export const loginFailure = createAction(Actions.LOGIN_FAILURE);

export const handleRedirectRequest = createAction(
  Actions.HANDLE_REDIRECT_REQUEST,
);
export const handleRedirectSuccess = createAction(
  Actions.HANDLE_REDIRECT_SUCCESS,
);
export const handleRedirectFailure = createAction(
  Actions.HANDLE_REDIRECT_FAILURE,
);

export const getAuthUserSuccess = createAction(Actions.GET_AUTH_USER_SUCCESS);

export const auth0AuthenticatedSuccess = createAction(
  Actions.AUTH0_AUTHENTICATED_SUCCESS,
);

export const logoutSuccess = createAction(Actions.LOGOUT_SUCCESS);

export const accessTokenSuccess = createAction(Actions.ACCESS_TOKEN_SUCCESS);

export const initAuth = () => {
  return async dispatch => {
    dispatch(loginRequest());
    try {
      let response = await fetch('/authconfig');
      response = await response.json();
      const client = await createAuth0Client({
        domain: response.domain,
        client_id: response.clientId,
        audience: response.audience,
      });
      dispatch(loginSuccess(client));
    } catch (err) {
      dispatch(loginFailure(err));
    }
  };
};

export const auth0Authenticated = () => {
  return async (dispatch, getState) => {
    const auth0 = getAuth0(getState());
    const response = await auth0.isAuthenticated();
    dispatch(auth0AuthenticatedSuccess(response));
  };
};

export const handleRedirectCallback = () => {
  return async (dispatch, getState) => {
    dispatch(handleRedirectRequest());
    try {
      const auth0 = getAuth0(getState());
      await auth0.handleRedirectCallback();
      window.history.replaceState({}, document.title, '/');
      dispatch(handleRedirectSuccess());
    } catch (err) {
      dispatch(handleRedirectFailure(err));
    }
  };
};

export const getAuthUser = () => {
  return async (dispatch, getState) => {
    const auth0 = getAuth0(getState());
    const user = await auth0.getUser();
    dispatch(getAuthUserSuccess(user));
  };
};

export const loginWithRedirect = () => {
  return async (dispatch, getState) => {
    const auth0 = getAuth0(getState());
    await auth0.loginWithRedirect({
      redirect_uri: window.location.href,
    });
  };
};

export const logout = () => {
  return async (dispatch, getState) => {
    const auth0 = getAuth0(getState());
    await auth0.logout({
      returnTo: window.location.origin,
    });
    dispatch(logoutSuccess());
  };
};

export const obtainAccessToken = () => {
  return async (dispatch, getState) => {
    const auth0 = getAuth0(getState());
    const token = await auth0.getTokenSilently();
    dispatch(accessTokenSuccess(token));
  };
};
