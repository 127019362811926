import get from 'lodash.get';
import { normalize } from 'normalizr';
import { createAction } from 'redux-actions';

import includeBearer from '../../fetch-wrapper';

import { Schemas } from './Schemas';
import { isNewEntity, getGroup, getSkillEntities } from './Selectors';

export const Actions = {
  GROUP_REQUEST: 'app/earth/GROUP_REQUEST',
  GROUP_SUCCESS: 'app/earth/GROUP_SUCCESS',
  GROUP_FAILURE: 'app/earth/GROUP_FAILURE',

  NORMALIZED_SKILLS_REQUEST: 'app/earth/NORMALIZED_SKILLS_REQUEST',
  NORMALIZED_SKILLS_SUCCESS: 'app/earth/NORMALIZED_SKILLS_SUCCESS',
  NORMALIZED_SKILLS_FAILURE: 'app/earth/NORMALIZED_SKILLS_FAILURE',

  SET_GROUP_NORMALIZED_SKILL_ID: 'app/earth/SET_GROUP_NORMALIZED_SKILL_ID',

  ADD_NORMALIZED_SKILL_TO_ENTITIES:
    'app/earth/ADD_NORMALIZED_SKILL_TO_ENTITIES',

  REMOVE_SKILL_FROM_GROUP: 'app/earth/REMOVE_SKILL_FROM_GROUP',

  ADD_GROUP_TO_STATE: 'app/earth/ADD_GROUP_TO_STATE',

  REMOVE_GROUP: 'app/earth/REMOVE_GROUP',

  SAVE_NEW_NORMALIZED_SKILL_FAILURE:
    'app/earth/SAVE_NEW_NORMALIZED_SKILL_FAILURE',

  SAVE_MAPPINGS_REQUEST: 'app/earth/SAVE_MAPPINGS_REQUEST',
  SAVE_MAPPINGS_SUCCESS: 'app/earth/SAVE_MAPPINGS_SUCCESS',
  SAVE_MAPPINGS_FAILURE: 'app/earth/SAVE_MAPPINGS_FAILURE',

  RELEVANCE_FEEDBACK_LOGGING_FAILURE:
    'app/earth/RELEVANCE_FEEDBACK_LOGGING_FAILURE',
};

export const groupRequest = createAction(Actions.GROUP_REQUEST);
export const groupSuccess = createAction(
  Actions.GROUP_SUCCESS,
  (groupId, entity) => ({ groupId, entity }),
);
export const groupFailure = createAction(Actions.GROUP_FAILURE);

export const normalizedSkillsRequest = createAction(
  Actions.NORMALIZED_SKILLS_REQUEST,
);
export const normalizedSkillsSuccess = createAction(
  Actions.NORMALIZED_SKILLS_SUCCESS,
);
export const normalizedSkillsFailure = createAction(
  Actions.NORMALIZED_SKILLS_FAILURE,
);

export const setGroupNormalizedSkillId = createAction(
  Actions.SET_GROUP_NORMALIZED_SKILL_ID,
  (groupId, skillId) => ({ groupId, skillId }),
);

export const addNormalizedSkillToEntities = createAction(
  Actions.ADD_NORMALIZED_SKILL_TO_ENTITIES,
);

export const removeSkillFromGroup = createAction(
  Actions.REMOVE_SKILL_FROM_GROUP,
  (groupId, skillId) => ({ groupId, skillId }),
);

export const addGroupToState = createAction(
  Actions.ADD_GROUP_TO_STATE,
  (groupId, entity) => ({ groupId, entity }),
);

export const removeGroup = createAction(Actions.REMOVE_GROUP);

export const saveNewNormalizedSkillFailure = createAction(
  Actions.SAVE_NEW_NORMALIZED_SKILL_FAILURE,
  (groupId, error) => ({ groupId, error }),
);

export const saveMappingsRequest = createAction(Actions.SAVE_MAPPINGS_REQUEST);
export const saveMappingsSuccess = createAction(Actions.SAVE_MAPPINGS_SUCCESS);
export const saveMappingsFailure = createAction(
  Actions.SAVE_MAPPINGS_FAILURE,
  (groupId, error) => ({ groupId, error }),
);

export const relevanceFeedbackLoggingFailure = createAction(
  Actions.RELEVANCE_FEEDBACK_LOGGING_FAILURE,
  (groupId, error) => ({ groupId, error }),
);

export const fetchGroup = () => {
  return async (dispatch, getState) => {
    dispatch(groupRequest());
    try {
      let response = await fetch('/grouping', includeBearer(getState()));
      response = await response.json();

      if (typeof response === 'number') {
        let suggestionResponse = await fetch('/suggestionlock');
        suggestionResponse = await suggestionResponse.json();
        throw {
          message: 'Groupings currently unavailable',
          description: `Please check back in ${suggestionResponse.lock} hours.`,
        };
      } else {
        const normalized = normalize(response, Schemas.GROUP);
        dispatch(groupSuccess(normalized.result, normalized.entities));
      }
    } catch (err) {
      dispatch(groupFailure(err));
    }
  };
};

export const fetchNormalizedSkills = name => {
  return async (dispatch, getState) => {
    dispatch(normalizedSkillsRequest());
    try {
      let response = await fetch(
        `/normalizedskills?name=${name}`,
        includeBearer(getState()),
      );
      response = await response.json();
      const normalized = normalize(response, Schemas.NORMALIZED_SKILLS);
      dispatch(normalizedSkillsSuccess(normalized));
    } catch (err) {
      dispatch(normalizedSkillsFailure(err));
    }
  };
};

export const updateGroupNormalizedSkill = (groupId, skill) => {
  return dispatch => {
    if (isNewEntity(skill.id)) {
      const normalized = normalize(skill, Schemas.NORMALIZED_SKILL);
      dispatch(
        addNormalizedSkillToEntities(normalized.entities.normalizedSkill),
      );
    }
    dispatch(setGroupNormalizedSkillId(groupId, skill.id));
  };
};

export const addGroup = skillId => {
  return dispatch => {
    const group = {
      id: `new-${skillId}-${Date.now()}`,
      normalizedSkill: null,
      skills: [skillId],
      saved: false,
    };
    const normalized = normalize(group, Schemas.GROUP);
    dispatch(addGroupToState(normalized.result, normalized.entities.group));
  };
};

export const saveGroup = groupId => {
  return async (dispatch, getState) => {
    const group = getGroup(getState(), groupId);
    let normalizedSkillId = get(group, 'normalizedSkill');
    const skillIds = get(group, 'skills');

    if (isNewEntity(normalizedSkillId)) {
      try {
        const name = get(
          getSkillEntities(getState()),
          `${normalizedSkillId}.name`,
        );
        const options = includeBearer(getState(), {
          method: 'POST',
          body: JSON.stringify({ name: name }),
        });
        let response = await fetch('/normalizedskills', options);
        response = await response.json();
        if (response.statusCode) {
          throw response;
        } else {
          normalizedSkillId = response.id;
        }
      } catch (err) {
        dispatch(saveNewNormalizedSkillFailure(groupId, err));
      }
    }
    await dispatch(saveMappings(normalizedSkillId, skillIds, groupId));
  };
};

export const saveMappings = (normalizedSkillId, skillIds, groupId) => {
  return async (dispatch, getState) => {
    dispatch(saveMappingsRequest(groupId));
    try {
      const options = includeBearer(getState(), {
        method: 'POST',
        body: JSON.stringify({
          normalizedSkillId: normalizedSkillId,
          skillIds: skillIds,
          groupId: groupId,
        }),
      });
      let response = await fetch('/normalizedskillsmapping', options);
      response = await response.json();
      if (response.statusCode) {
        throw response;
      } else {
        dispatch(saveMappingsSuccess(groupId));
      }
    } catch (err) {
      dispatch(saveMappingsFailure(groupId, err));
    }
  };
};

export const removeSkill = (groupId, skillId) => {
  return async (dispatch, getState) => {
    const group = getGroup(getState(), groupId);
    const normalizedSkillName = get(
      getSkillEntities(getState()),
      group.normalizedSkill,
    ).name;
    const skillName = get(getSkillEntities(getState()), skillId).name;

    try {
      const options = includeBearer(getState(), {
        method: 'POST',
        body: JSON.stringify({
          normalizedSkillName: normalizedSkillName,
          skillName: skillName,
        }),
      });
      await fetch('/donotbelongskills', options);
      dispatch(removeSkillFromGroup(groupId, skillId));
    } catch (err) {
      dispatch(relevanceFeedbackLoggingFailure(groupId, err));
    }
  };
};
