import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { CTAButton } from '../../components';

import {
  auth0Authenticated,
  initAuth,
  getAuthUser,
  handleRedirectCallback,
  obtainAccessToken,
  logout,
  loginWithRedirect,
} from './Actions';
import { getIsAuthenticated, getUser } from './Selectors';

import { Wrapper } from './Login.sc';

export class Login extends Component {
  async componentDidMount() {
    await this.props.initAuth();
    await this.props.auth0Authenticated();

    const query = window.location.search;
    if (query.includes('code=') && query.includes('state=')) {
      await this.props.handleRedirectCallback();
    }

    await this.props.obtainAccessToken();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.isAuthenticated &&
      prevProps.isAuthenticated !== this.props.isAuthenticated
    ) {
      this.props.getAuthUser();
    }
  }

  render() {
    const { isAuthenticated, user } = this.props;
    return (
      <Wrapper>
        <strong>Welcome{user ? ` ${user.name}` : ''}!</strong>
        <div>
          {!isAuthenticated ? (
            <CTAButton
              label="Login"
              isSmall={true}
              icon="sign-in-alt"
              onClick={this.props.onClickLogin}
            />
          ) : null}
          {isAuthenticated ? (
            <CTAButton
              label="Logout"
              isSmall={true}
              icon="sign-out-alt"
              onClick={this.props.onClickLogout}
            />
          ) : null}
        </div>
      </Wrapper>
    );
  }
}

Login.propTypes = {
  isAuthenticated: PropTypes.bool,

  auth0Authenticated: PropTypes.func,
  getAuthUser: PropTypes.func,
  handleRedirectCallback: PropTypes.func,
  initAuth: PropTypes.func,
  obtainAccessToken: PropTypes.func,
  onClickLogin: PropTypes.func,
  onClickLogout: PropTypes.func,

  user: PropTypes.object,
};

export default connect(
  state => ({
    isAuthenticated: getIsAuthenticated(state),
    user: getUser(state),
  }),
  dispatch => ({
    auth0Authenticated: () => dispatch(auth0Authenticated()),
    getAuthUser: () => dispatch(getAuthUser()),
    handleRedirectCallback: () => dispatch(handleRedirectCallback()),
    initAuth: () => dispatch(initAuth()),
    obtainAccessToken: () => dispatch(obtainAccessToken()),
    onClickLogin: () => dispatch(loginWithRedirect()),
    onClickLogout: () => dispatch(logout()),
  }),
)(Login);
