import React from 'react';

import styled from 'styled-components';

import { PROJECTS } from '../constants';

const Landing = () => (
  <React.Fragment>
    <Logo src={require('./glints-logo.gif')} />
    <TitleWrapper>
      <Title>Blue</Title>
    </TitleWrapper>
    <IconsWrapper>
      {PROJECTS.map(x => (
        <Icon key={x.path} className={`fas fa-${x.icon} fa-4x`} />
      ))}
    </IconsWrapper>
  </React.Fragment>
);

const IconsWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
`;

const Logo = styled.img`
  display: flex;
  margin: 0 auto;
  height: 375px;
  margin-bottom: -120px;
`;

const TitleWrapper = styled.div`
  padding-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
  font-size: 60px;
  font-weight: bold;
  color: #32407b;
  text-shadow: 3px 3px 2px #465881, 6px 6px 2px #46b5d1;
`;

const Icon = styled.i`
  margin-right: 5px;
`;

export default Landing;
