export const PROJECTS = [
  { name: 'Avatar', icon: 'hand-spock', path: 'avatar' },
  { name: 'Earth', icon: 'globe-asia', path: 'earth' },
  { name: 'Ice', icon: 'igloo', path: 'ice' },
  { name: 'Ocean', icon: 'water', path: 'ocean' },
  { name: 'Universe', icon: 'atom', path: 'universe' },
];

export const PAGES = {
  AVATAR: 'avatar',
  EARTH: 'earth',
  ICE: 'ice',
  OCEAN: 'ocean',
  UNIVERSE: 'universe',
};
