import React, { Component } from 'react';

import styled, { keyframes } from 'styled-components';

class Loader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: items[0],
    };
  }

  componentDidMount() {
    const item = items[Math.floor(Math.random() * items.length)];
    this.setState({
      selected: item,
    });
  }

  render() {
    const { selected } = this.state;
    return (
      <Wrapper>
        <p>
          <i>Fetching skills to be curated...</i>
        </p>
        <br />
        <br />
        <Icon className={`fas fa-4x fa-${selected.icon}`} />
        <br />
        <br />
        <p>
          <small>
            <i>{selected.text}</i>
          </small>
        </p>
      </Wrapper>
    );
  }
}

export const items = [
  {
    icon: 'cat',
    text: 'Hey there, good lookin`! Hope you`re feline good today!',
  },
  { icon: 'crow', text: 'Slack Mr. CEO to get your free cawfee today!' },
  { icon: 'dog', text: 'Welcome to the bark side of a clean and green Earth.' },
  {
    icon: 'dove',
    text: 'Everyone at Glints doves you for taking on this ardous task!',
  },
  {
    icon: 'dragon',
    text:
      'Taking too long to load? Lighting a fire under Earth`s ass right now.',
  },
  {
    icon: 'fish',
    text: 'Take care of our data, don`t leave it to salmon else.',
  },
  { icon: 'frog', text: 'We will all be hoppier when this is done.' },
  { icon: 'hippo', text: 'Be like two hippos riding a bicycle. Optimistic!' },
  { icon: 'horse', text: 'Quit horsing around and get to work!' },
  { icon: 'kiwi-bird', text: 'Blue is the kiwi to everything!' },
  { icon: 'otter', text: 'You are otterly lovely.' },
  { icon: 'spider', text: 'Flyday is my favourite day of the week.' },
];

const Wrapper = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 30px;
`;

const moveBackAndForth = keyframes`
  0% {
    transform: translateX(45px);
  }
  50% {
    transform: translateX(-45px);
  }
  100% {
    transform: translateX(45px);
  }
`;

const Icon = styled.i`
  animation: 2s ease-out 0s 500 ${moveBackAndForth};
`;

export default Loader;
