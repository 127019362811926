import React, { Component } from 'react';

import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route } from 'react-router';

import { Landing, LandingAuthenticated } from './components';
import { PAGES, PROJECTS } from './constants';
import {
  Avatar,
  Earth,
  Ice,
  Login,
  Ocean,
  Universe,
  getIsAuthenticated,
} from './modules';

import {
  Content,
  Icon,
  Link,
  LinkText,
  Navlinks,
  Title,
  TitleWrapper,
  Wrapper,
} from './App.sc';

export class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: '/',
    };
  }

  componentDidMount() {
    const pathname = window.location.pathname.split('/')[1];
    this.setState({
      selected: pathname,
    });
  }

  onClickProject(name) {
    this.setState({
      selected: name,
    });
  }

  render() {
    const { isAuthenticated } = this.props;
    const { selected } = this.state;

    return (
      <React.Fragment>
        <Login />
        {!isAuthenticated ? <Landing /> : null}
        {isAuthenticated ? (
          <React.Fragment>
            <TitleWrapper>
              <Title>Blue</Title>
            </TitleWrapper>
            <Wrapper>
              <Navlinks>
                {PROJECTS.map(x => (
                  <Link
                    to={x.path}
                    key={x.path}
                    onClick={() => this.onClickProject(x.path)}
                  >
                    <LinkText
                      isselected={selected === x.path ? 'true' : 'false'}
                    >
                      <Icon className={`fas fa-${x.icon}`} />
                      {x.name}
                    </LinkText>
                  </Link>
                ))}
              </Navlinks>
              <Content>
                <Route exact={true} path="/" component={LandingAuthenticated} />
                <Route path={`/${PAGES.AVATAR}`} component={Avatar} />
                <Route path={`/${PAGES.EARTH}`} component={Earth} />
                <Route path={`/${PAGES.ICE}`} component={Ice} />
                <Route path={`/${PAGES.OCEAN}`} component={Ocean} />
                <Route path={`/${PAGES.UNIVERSE}`} component={Universe} />
              </Content>
            </Wrapper>
          </React.Fragment>
        ) : null}
      </React.Fragment>
    );
  }
}

App.propTypes = {
  isAuthenticated: PropTypes.bool,
};

export default connect(
  state => ({
    isAuthenticated: getIsAuthenticated(state),
  }),
  () => ({
    goToPage: page => push(page),
  }),
)(App);
