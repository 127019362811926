import React from 'react';

import { Content, Header, Left, Right } from './Avatar.sc';

const Avatar = () => (
  <React.Fragment>
    <Header>
      <h2>
        <i className="fas fa-hand-spock" />
        &nbsp;Avatar: Under Construction
      </h2>
    </Header>
    <Content>
      <Left />
      <Right />
    </Content>
  </React.Fragment>
);

export default Avatar;
