import { handleActions } from 'redux-actions';

import { Actions } from './Actions';

export const initialState = {
  auth0: null,
  loading: false,
  success: false,
  error: null,
  isAuthenticated: false,
  user: null,
  accessToken: null,
};

export default handleActions(
  {
    [Actions.LOGIN_REQUEST]: () => ({
      ...initialState,
      loading: true,
    }),
    [Actions.LOGIN_SUCCESS]: (state, action) => ({
      ...state,
      auth0: action.payload,
      loading: false,
      success: true,
      error: null,
    }),
    [Actions.LOGIN_FAILURE]: (state, action) => ({
      ...initialState,
      error: action.payload,
    }),
    [Actions.HANDLE_REDIRECT_SUCCESS]: state => ({
      ...state,
      isAuthenticated: true,
    }),
    [Actions.GET_AUTH_USER_SUCCESS]: (state, action) => ({
      ...state,
      user: action.payload,
    }),
    [Actions.AUTH0_AUTHENTICATED_SUCCESS]: (state, action) => ({
      ...state,
      isAuthenticated: action.payload,
    }),
    [Actions.ACCESS_TOKEN_SUCCESS]: (state, action) => ({
      ...state,
      accessToken: action.payload,
    }),
    [Actions.LOGOUT_SUCCESS]: () => initialState,
  },
  initialState,
);
