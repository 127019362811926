import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

const Error = ({ description, message }) => (
  <Wrapper>
    <Message>{message}</Message>
    <p>{description}</p>
  </Wrapper>
);

Error.propTypes = {
  description: PropTypes.string,
  message: PropTypes.string.isRequired,
};

const Wrapper = styled.div`
  border: 2px solid #d35656;
  background-color: #ffe0e0;
  padding: 25px 20px;
  margin-bottom: 30px;
`;

const Message = styled.h3`
  font-weight: bold;
  margin: 5px;
`;

export default Error;
